import React from 'react';

export default function(__params = {}) {
  const {
    brandUrl,
    cartUrl,
    isCtaFloat,
    isHeadLogo,
    isHeadLogoWhite,
    isHeader,
    isLoggedIn,
    isRedirect,
    loginUrl,
    questionUrl,
    questionUrlCm,
    quizUrl,
    sfUrl
  } = __params;
  return (
    isHeader && (
      <header className="l-header">
        <div className="l-header__topbar">
          <button className="l-header__hamburger js-nav__toggle" type="button">
            <div className="c-hamburger">
              <div className="c-hamburger__line1"></div>
              <div className="c-hamburger__line2"></div>
              <div className="c-hamburger__line3"></div>
            </div>
          </button>
          {isCtaFloat && (
            <div className="l-header__btn">
              <div className="p-headerQuizBtn js-headerQuizBtn">
                <div className="p-headerQuizBtn__button">
                  {!quizUrl && (
                    <a className="button" href={questionUrlCm}>
                      <span></span>ONLINE肌診断(無料)<span>➝</span>
                    </a>
                  )}
                  {quizUrl && (
                    <a className="button" href={quizUrl}>
                      <span></span>ONLINE肌診断(無料)<span>➝</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
          {isHeadLogo && !isHeadLogoWhite && (
            <h1 className="l-header__logo js-headerLogo">
              <a href={brandUrl}>
                <img src="/images/common/c-logo-tac--black.svg" alt="HOTARU PERSONALIZED" />
              </a>
            </h1>
          )}
          {isHeadLogo && isHeadLogoWhite && (
            <h1 className="l-header__logo js-headerLogo">
              <a href={brandUrl}>
                <img src="/images/common/c-logo-tac--white.svg" alt="HOTARU PERSONALIZED" />
              </a>
            </h1>
          )}
        </div>
        <div className="l-header__nav">
          <nav className="l-nav l-nav--type-left-slide js-nav">
            <div className="l-nav__modal">
              <div className="l-nav__modal__bg js-nav__bg"></div>
              <div className="l-nav__modal__content">
                <div className="l-nav__modal__inner">
                  <div className="p-globalNav">
                    <div className="p-globalNav__banner">
                      {isRedirect && (
                        <a className="p-button p-button--circle-right p-button--has-icon" href={questionUrlCm}>
                          <span className="p-button__icon">
                            <img src="/images/common/icons/icon-question.png" alt="" />
                          </span>
                          <span className="p-button__label">スキンケアをカスタマイズする</span>
                        </a>
                      )}
                      {!isLoggedIn && !isRedirect && (
                        <a className="p-button p-button--circle-right p-button--has-icon" href={questionUrlCm}>
                          <span className="p-button__icon">
                            <img src="/images/common/icons/icon-question.png" alt="" />
                          </span>
                          <span className="p-button__label">ONLINE 無料診断</span>
                        </a>
                      )}
                      {isLoggedIn && !isRedirect && (
                        <a
                          className="p-button p-button--circle-right p-button--has-icon"
                          href={cartUrl + '/user/hotaru/counseling/next/question'}
                        >
                          <span className="p-button__icon">
                            <img src="/images/common/icons/icon-re-question.png" alt="" />
                          </span>
                          <span className="p-button__label">
                            今の肌状態を確認して
                            <br />
                            再診断する
                          </span>
                        </a>
                      )}
                    </div>
                    <ul className="p-globalNav__list">
                      {isRedirect && (
                        <li>
                          <a href={loginUrl}>ログイン / マイページ</a>
                        </li>
                      )}
                      {!isLoggedIn && !isRedirect && (
                        <li>
                          <a href={cartUrl + '/sign_in'}>ログイン / マイページ</a>
                        </li>
                      )}
                      {isLoggedIn && !isRedirect && (
                        <li>
                          <a href="">マイページ</a>
                        </li>
                      )}
                      <li>
                        <a className="u-spacing1" href={brandUrl + '/about/hotaru'}>
                          ABOUT
                        </a>
                      </li>
                      <li>
                        <a href={brandUrl + '/news'}>NEWS</a>
                      </li>
                      <li>
                        <a href={sfUrl} target="_blank" rel="noopener">
                          Q & A
                        </a>
                      </li>
                    </ul>
                    <div className="p-globalNav__btm">
                      <a className="p-globalNav__store" href={questionUrl}>
                        店舗の方はこちら
                      </a>
                      <div className="p-globalNav__btm__right">
                        <ul className="p-globalNav__sns-icon">
                          <li>
                            <a
                              className="c-icon c-icon--ig c-icon--ig-large"
                              href="https://www.instagram.com/hotaru_personalized/"
                              target="_blank"
                            ></a>
                          </li>
                        </ul>
                        {isLoggedIn && (
                          <a className="p-globalNav__logout" href="">
                            LOGOUT
                          </a>
                        )}
                      </div>
                    </div>
                    {!isLoggedIn && (
                      <a
                        className="p-globalNav__banner"
                        href="https://www.eposcard.co.jp/collabo/sparty/index.html?cid=spt_ts_rp_log_h"
                        target="_blank"
                      >
                        <img
                          className="p-globalNav__banner-img"
                          src="/images/common/logo/epos-shopping-banner.jpg?v=20231127"
                          alt="Spartyエポスカード｜クレジットカードはエポスカード "
                          width="100%"
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  );
}
