import React from 'react';

export default function() {
  return (
    <div className="l-loader js-loader">
      <div className="l-loader__indi js-loader__indi is-hidden js-preload-bar"></div>
      <div className="l-loader__pjax js-loader__pjax is-hidden">
        <div className="p-loading c-loading p-loading--grad"></div>
      </div>
      <div className="l-loader__load js-loader__load is-active">
        <div className="c-circleLoading js-circleLoading">
          <div className="c-circleLoading__container">
            <div className="c-circleLoading__fill-base"></div>
            <div className="c-circleLoading__indicator">
              <div className="c-circleLoading__indicator__fill js-circleLoading__indicator__fill"></div>
              <div className="c-circleLoading__indicator__mask"></div>
            </div>
            <div className="c-circleLoading__fill-center"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
