import React from "react";

import cursor from '../pug/_layout/_l-cursor.pug';

class L_cursor extends React.Component {
    render() {
        return cursor.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default L_cursor