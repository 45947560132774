import React from 'react';

export default function() {
  return (
    <div className="l-cursor js-cursor">
      <div className="c-doubleCircleCursor js-doubleCircleCursor">
        <div className="c-doubleCircleCursor__cursor js-doubleCircleCursor__cursor"></div>
        <div className="c-doubleCircleCursor__chaser js-doubleCircleCursor__chaser"></div>
      </div>
    </div>
  );
}
