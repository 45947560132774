import React from "react";

import footer from '../pug/_layout/_l-footer.pug';
import PropTypes from "prop-types";

class L_footer extends React.Component {
    render() {
        const { quizUrl } = this.props
        const isRedirect = true;

        const cartUrl = process.env.CART_SITE_URL;
        const sfUrl = process.env.SF_SITE_URL;
        const brandUrl = process.env.GATSBY_SITE_URL;
        const companyUrl = process.env.COMPANY_URL;
        const faqUrl = 'https://sparty.my.site.com/spartyshopfaq/s/article/HOTARU-other-1';
        const medullaQuestionUrl = process.env.QUESTION_URL_INTEGRATED_FORM;
        const medullaLoginUrl = process.env.LOGIN_URL;

        return footer.call(this, {
            cartUrl,
            sfUrl,
            brandUrl,
            companyUrl,
            faqUrl,
            isRedirect,
            medullaQuestionUrl,
            medullaLoginUrl,
            quizUrl,
        });
    }
}
L_footer.propTypes = {
    quizUrl: PropTypes.string,
}

L_footer.defaultProps = {
    quizUrl: ``,
}
export default L_footer