import React from 'react';

export default function(__params = {}) {
  const { brandUrl, cartUrl, companyUrl, faqUrl, isRedirect, medullaQuestionUrl, quizUrl, sfUrl } = __params;
  return (
    <footer className="l-footer t-footer">
      <div className="l-footer__menu">
        <div className="t-footer__menu">
          <div className="c-grid__row">
            <div className="t-footer__left">
              <div className="t-footer__logo">
                <a href={brandUrl}>
                  <img src="/images/common/c-logo-tal--white.svg" alt="HOTARU PERSONALIZED" />
                </a>
              </div>
              <ul className="t-footer__sns">
                <li>
                  <a
                    className="c-colorHv c-icon c-icon--ig"
                    href="https://www.instagram.com/hotaru_personalized/"
                    target="_blank"
                  ></a>
                </li>
              </ul>
              <small className="t-footer__copyright">
                COPYRIGHT&copy;2020 SPARTY
                <br />
                ALL RIGHTS RESERVED
              </small>
            </div>
            <div className="t-footer__vline"></div>
            <div className="c-grid__col md-6 t-footer__right">
              <nav className="t-footer__navs">
                <ul className="t-footer__gnav">
                  {isRedirect && quizUrl && (
                    <li>
                      <a className="c-colorHv" id="footerQuizUrl" href={quizUrl}>
                        <span>スキンケアをカスタマイズする</span>
                      </a>
                    </li>
                  )}
                  {isRedirect && !quizUrl && (
                    <li>
                      <a className="c-colorHv" id="footerQuizUrl" href={medullaQuestionUrl}>
                        <span>スキンケアをカスタマイズする</span>
                      </a>
                    </li>
                  )}
                  {!isRedirect && (
                    <li>
                      <a className="c-colorHv" href={cartUrl + '/hotaru/questions?openExternalBrowser=1'}>
                        <span>スキンケアをカスタマイズする</span>
                      </a>
                    </li>
                  )}
                  <li>
                    <a className="c-colorHv" href={brandUrl + '/about/hotaru'}>
                      <span>HOTARUとは</span>
                    </a>
                  </li>
                  <li>
                    <a className="c-colorHv" href={brandUrl + '/news'}>
                      <span>NEWS</span>
                    </a>
                  </li>
                </ul>
                <ul className="t-footer__snav">
                  <li>
                    <a className="c-colorHv" href={cartUrl + '/privacy'}>
                      <span>プライバシーポリシー</span>
                    </a>
                  </li>
                  <li>
                    <a className="c-colorHv" href={companyUrl} target="_blank" rel="noopener">
                      <span>運営会社</span>
                    </a>
                  </li>
                  <li>
                    <a className="c-colorHv" href={cartUrl + '/hotaru/trade-law'}>
                      <span>特定商取引に基づく表記</span>
                    </a>
                  </li>
                  <li>
                    <a className="c-colorHv" href={sfUrl} target="_blank" rel="noopener">
                      <span>よくあるご質問</span>
                    </a>
                  </li>
                  <li>
                    <a className="c-colorHv" href={cartUrl + '/agreement'}>
                      <span>利用規約</span>
                    </a>
                  </li>
                  <li>
                    <a className="c-colorHv" href={faqUrl} target="_blank" rel="noopener">
                      <span>お問い合わせ</span>
                    </a>
                  </li>
                  <li>
                    <a className="c-colorHv" href={cartUrl + '/security'}>
                      <span>セキュリティーポリシー</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="t-footer__menu__initial-logo">
              <img src="/images/top/pc/ft_initial_logo.png" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
