import React from "react";

import guide from '../pug/_layout/_l-guide.pug';

class L_guide extends React.Component {
    render() {
        return guide.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default L_guide