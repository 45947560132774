import PropTypes from "prop-types"
import React from "react"

import L_HEADER from "../components/l_header"

const Header = ({ isHeader, quizUrl, isHeadLogo, isHeadLogoWhite, isCtaFloat }) => (
  <L_HEADER isHeader={isHeader} quizUrl={quizUrl} isHeadLogo={isHeadLogo} isHeadLogoWhite={isHeadLogoWhite}  isCtaFloat={isCtaFloat} />
  )

export default Header
