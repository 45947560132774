import React from 'react';

export default function() {
  return (
    <div className="l-guide js-guide blend">
      <div className="l-guide__inner">
        <div className="l-guide__size">
          <div className="l-guide__size__bg">
            <span></span>
          </div>
          <div className="l-guide__size__w js-guide__size__w"></div>
        </div>
        <div className="l-guide__pc">
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
          <div className="l-guide__pc__grid">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}
