/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "../css/style.scss"
import Header from "./header"

import Footer from "./footer";
import L_LOADER from "../components/l_loader"
import L_GUIDE from "../components/l_guide"
import L_CURSOR from  "../components/l_cursor"

const Layout = ({ children, sectionClasses, dataNamespace, isShowFooter = true, isHeader = true, quizUrl = "", isHeadLogo = true, isHeadLogoWhite = false, isCtaFloat = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (isShowFooter) {
    return (
      <>
        <div className="js-all">
          <L_LOADER />
          <Header siteTitle={data.site.siteMetadata.title} isHeader={isHeader} quizUrl={quizUrl} isHeadLogo={isHeadLogo} isHeadLogoWhite={isHeadLogoWhite} isCtaFloat={isCtaFloat} />
          <main id="js-router">
            <article className="js-article" data-namespace={dataNamespace}>
              <section className={sectionClasses}>
                {children}
              </section>
              <Footer quizUrl={quizUrl}/>
            </article>
          </main>
          <div className="l-fixed" />
          <L_CURSOR />
          <L_GUIDE />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="js-all">
          <L_LOADER />
          <Header siteTitle={data.site.siteMetadata.title} isHeader={isHeader} quizUrl={quizUrl} />
          <main id="js-router">
            <article className="js-article" data-namespace={dataNamespace}>
              <section className={sectionClasses}>
                {children}
              </section>
            </article>
          </main>
          <div className="l-fixed" />
          <L_CURSOR />
          <L_GUIDE />
        </div>
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
