import React from "react";
import PropTypes from "prop-types"
import header from '../pug/_layout/_l-header.pug';

class L_header extends React.Component {

    render() {
        const { isHeader, quizUrl, isHeadLogo, isHeadLogoWhite, isCtaFloat } = this.props
        const isRedirect = true;
        const isLoggedIn = false; // true:ログイン済

        const cartUrl = process.env.CART_SITE_URL;
        const sfUrl = process.env.SF_SITE_URL;
        const brandUrl = process.env.GATSBY_SITE_URL;
        const companyUrl = process.env.COMPANY_URL;
        const questionUrlCm = process.env.QUESTION_URL_INTEGRATED_FORM;
        const questionUrl = process.env.QUESTION_URL;
        const loginUrl = process.env.LOGIN_URL;

        return header.call(this, {
            cartUrl,
            sfUrl,
            brandUrl,
            companyUrl,
            isLoggedIn,
            isRedirect,
            questionUrlCm,
            questionUrl,
            loginUrl,
            isHeader,
            quizUrl,
            isHeadLogo,
            isHeadLogoWhite,
            isCtaFloat,
        });
    }
}
L_header.propTypes = {
    isHeader: PropTypes.bool,
    quizUrl: PropTypes.string,
    isHeadLogo: PropTypes.bool,
    isHeadLogoWhite: PropTypes.bool,
    isCtaFloat: PropTypes.bool,
}

L_header.defaultProps = {
    isHeader: true,
    quizUrl: ``,
    isHeadLogo: true,
    isHeadLogoWhite: false,
    isCtaFloat: true,
}
export default L_header